<template>
  <div>
    <div>
      <el-dialog
          :visible.sync="dialogVisible"
          width="644px"
          :before-close="handleClose"
          top="30vh"
      >
        <div class="login">
          <div class="title">上传附件</div>
          <div>
            <el-upload
                class="upload-demo"
                drag
                :action="`/system/resume/Resume_upload?userId=${userId}`"
                :data="{ userId: userId }"
                multiple
                :on-success="handleSuccess"
                :on-error="handleError">
              <i class="el-icon-upload"></i>
              <div class="text">拖拽文件到这里，<em>快速上传</em></div>
              <div class="text1">简历建议使用PDF文件，也支持JPG、PNG格式</div>
              <div class="text2">文件大小不超过20M</div>
              <div class="text3">上传简历附件</div>
            </el-upload>
          </div>


        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
export default {
  components:{
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  }, data(){
    return{
      userId:localStorage.getItem("userId"),
      baseURL: this.$utils.baseURL,
      isMobile:false,
      languageSelect:''
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    handleSuccess(response) {
      // Handle successful upload here
      localStorage.setItem("resume",JSON.stringify(response))
      console.log(response)
      this.$message({
        message: response.msg, //提示的信息
        type: "warning", //类型是成功
        duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      });
      this.$emit('hideDialog')
      // Process 'response' data as needed
    },
    handleError(error) {
      // Handle upload error here
      console.error('Upload failed!', error);


      // Handle the error or display a message to the user
    },
    handleClose() {
      this.$emit('hideDialog')
    },
    showRegister(){
      this.$emit('register')
    },
    forget(){
      this.$emit('forget')
    }
  },
};
</script>
<style scoped lang="scss">
.login{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  width: 638px;
  height: fit-content;
  padding:0 0 15px 0;

  .title{
    width: 100%;
    margin:0 auto;
    padding-top: 32px;
    height: 80px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    -webkit-background-clip: text;
    background: linear-gradient(168deg, #FFDBE1 26%, rgba(255,255,255,0) 100%);
    border-radius: 4px 4px 4px 4px;
    opacity: 0.4;
  }
}
.text{
  height: 20px;
  font-size: 16px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.text1{
  height: 20px;
  font-size: 12px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.text2{
  height: 20px;
  font-size: 12px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #BF0022;
  line-height: 20px;
}
.text3{
  margin: 20px auto;
  width: 158px;
  height: 32px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #D5DAE0;
  font-size: 14px;
  background: #BF0022;
  display: flex;
  align-items: center;
  justify-content: center;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
::v-deep .el-upload-dragger {
  background-color: #fff;
  box-sizing: border-box;
  width: 578px;
  height: 311px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #D5DAE0;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
}
::v-deep {
  .el-dialog__header {

    .el-dialog__title {
      padding: 0;
    }
  }
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #FFFFFF;

  }
  .el-dialog__headerbtn {
    position: absolute;
    top: -21px;
    right: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    color:black;
  }
}
</style>
