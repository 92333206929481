<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :visible.sync="dialogVisible"
          width="644px"
          :before-close="handleClose"
          top="5vh"
          min-height="90vh"
      >
        <div class="login">
          <div class="list">

            <div v-if="trans === false">
              <!--      PDF-->
              <div>
                <pdf v-for="i in pageNum" :key="i" :src="filePath" :page="i"></pdf>
              </div>

            </div>
            <div v-else>
              <dv-loading style=" line-height: 2;color: var(--main-color);margin-top: 20vh" class="detail">
                Loading...
              </dv-loading>
            </div>
            <!--      翻译-->

          </div>
        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import pdf from "vue-pdf";

export default {
  components:{
    pdf
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    filePath:{
      type: String,
      required: true,
    }
  }, data(){
    return{
      trans: true,
      pageNum: 1,
      translateThis: 1,
      userId:localStorage.getItem("userId"),
      baseURL: this.$utils.baseURL,
      isMobile:false,
      languageSelect:''
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    console.log("path",this.filePath)
    this.getPageNum ()

  },
  methods: {

    getPageNum() {
      console.log("155633223");
      console.log(this.filePath);

      let loadingTask = pdf.createLoadingTask(this.filePath);

      loadingTask.promise.then((pdf) => {
        this.pageNum = pdf.numPages;
        this.trans = false;
      }).catch((err) => {
        console.error('PDF加载失败', err);
      });
    },

    handleSuccess(response) {
      // Handle successful upload here
      localStorage.setItem("resume",JSON.stringify(response))
      console.log(response)
      this.$message({
        message: response.msg, //提示的信息
        type: "warning", //类型是成功
        duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      });
      this.$emit('hideDialog')
      // Process 'response' data as needed
    },
    handleError(error) {
      // Handle upload error here
      console.error('Upload failed!', error);


      // Handle the error or display a message to the user
    },
    handleClose() {
      this.$emit('hideDialog')
    },
    showRegister(){
      this.$emit('register')
    },
    forget(){
      this.$emit('forget')
    }
  },
  watch: {
    filePath(newValue) {
      console.log('filePath changed:', newValue);

      this.getPageNum ()
      // Perform any actions based on the updated value
    }
  },

};
</script>
<style scoped lang="scss">
.login{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  width: 638px;
  height: fit-content;
  padding:0 0 15px 0;

  .title{
    width: 100%;
    margin:0 auto;
    padding-top: 32px;
    height: 80px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    -webkit-background-clip: text;
    background: linear-gradient(168deg, #FFDBE1 26%, rgba(255,255,255,0) 100%);
    border-radius: 4px 4px 4px 4px;
    opacity: 0.4;
  }
}
.text{
  height: 20px;
  font-size: 16px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.text1{
  height: 20px;
  font-size: 12px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.text2{
  height: 20px;
  font-size: 12px;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #BF0022;
  line-height: 20px;
}
.text3{
  margin: 20px auto;
  width: 158px;
  height: 32px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #D5DAE0;
  font-size: 14px;
  background: #BF0022;
  display: flex;
  align-items: center;
  justify-content: center;
   font-family: arial, sans-serif, "Microsoft Yahei";
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
::v-deep .el-upload-dragger {
  background-color: #fff;
  box-sizing: border-box;
  width: 578px;
  height: 311px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #D5DAE0;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
}
::v-deep {
  .el-dialog__header {

    .el-dialog__title {
      padding: 0;
    }
  }
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #FFFFFF;

  }
  .el-dialog__headerbtn {
    position: absolute;
    top: -21px;
    right: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    color:black;
  }
}
</style>
